import Vue from 'vue'
import VueRouter from 'vue-router'
import AppMap from '@/views/Map'
import SignIn from '@/views/SignIn.vue'
import AppMapContainer from '@/views/MapCointainer.vue'
import {getItem} from '@/helpers/persistanceStorage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    // redirect: '/signin',
    name: 'login',
    component: SignIn,
  },
  {
    path: '/map',
    name: 'map',
    component: AppMap,
  },
  {
    path: '/',
    name: 'home',
    component: AppMapContainer,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
})
router.beforeEach(async (to, from, next) => {
  if (to.name !== 'login' && !getItem('accessToken')) {
    next({name: 'login'})
  } else {
    next()
  }

  // if the user is not authenticated, `next` is called twice
})

export default router
