import mapApi from '@/api/map'

const state = {
  zoomLevel: 17,
  filters: [],
  isLoading: false,
  filterRemoved: null,
  filterAdded: null,
  inspections: [],
  objectClicked: null,
  pointerPos: null,
  newObjects: [],
}
export const mutationTypes = {
  pointerPos: '[map] pointerPos',
  zoomChange: '[map] zoomChange',
  objectClicked: '[map] ObjectClicked',
  isLoading: '[map] IsLoading',
  filtersStart: '[map] FiltersStart',
  filtersSuccess: '[map] FiltersSuccess',
  filtersFailure: '[map] FiltersFailure',
  filterRemoved: '[map] FilterRemoved',
  filterAdded: '[map] filterAdded',
  inspectionsStart: '[map] InspectionsStart',
  inspectionsSuccess: '[map] InspectionsSuccess',
  inspectionsFailure: '[map] InspectionsFailure',
}

export const actionTypes = {
  filters: '[map] filters',
  inspections: '[map] inspections',
}

export const getterTypes = {
  filterAdded: '[map] filterAdded',
  filterRemoved: '[map] FilterRemoved',
  objectClicked: '[map] objectClicked',
  zoomState: '[map] zoomState',
  filtersState: '[map] filtersState',
  isLoadingState: '[map] isLoadingState',
  inspectionsState: '[map] inspectionsState',
  pointerPos: '[map] pointerPos',
}

const getters = {
  [getterTypes.filterRemoved]: (state) => {
    return state.filterRemoved
  },
  [getterTypes.filterAdded]: (state) => {
    return state.filterAdded
  },
  [getterTypes.pointerPos]: (state) => {
    return state.pointerPos
  },
  [getterTypes.objectClicked]: (state) => {
    return state.objectClicked
  },
  [getterTypes.isLoadingState]: (state) => {
    return state.isLoading
  },
  [getterTypes.filtersState]: (state) => {
    return state.filters
  },
  [getterTypes.zoomState]: (state) => {
    return state.zoomLevel
  },
  [getterTypes.inspectionsState]: (state) => {
    return state.inspections
  },
}

const mutations = {
  [mutationTypes.filterRemoved](state, payload) {
    state.filterRemoved = payload
  },
  [getterTypes.filterAdded]: (state, payload) => {
    state.filterAdded = payload;
  },
  [mutationTypes.noFiltersSelected](state, payload) {
    state.noFiltersSelected = payload
  },
  [mutationTypes.isLoading](state, payload) {
    state.isLoading = payload
  },
  [mutationTypes.pointerPos](state, payload) {
    state.pointerPos = payload
  },
  [mutationTypes.objectClicked](state, payload) {
    state.objectClicked = payload
  },
  [mutationTypes.zoomChange](state, payload) {
    state.zoomLevel = payload
  },
  [mutationTypes.filtersStart](state) {
    state.isLoading = true
  },
  [mutationTypes.filtersSuccess](state, payload) {
    state.isLoading = false
    state.filters = payload
  },
  [mutationTypes.filtersFailure](state) {
    state.isLoading = false
    state.filters = null
  },
  [mutationTypes.inspectionsStart](state) {
    state.isLoading = true
  },
  [mutationTypes.inspectionsSuccess](state, payload) {
    state.isLoading = false
    state.inspections = payload
  },
  [mutationTypes.inspectionsFailure](state) {
    state.isLoading = false
    state.inspections = null
  },
}
const actions = {
  [actionTypes.filters](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.filtersStart)
      mapApi
        .getFilters()
        .then((response) => {
          context.commit(
            mutationTypes.filtersSuccess,
            response.data.returndata.filters
          )
          resolve(response.data.returndata.filters)
        })
        .catch(() => {
          context.commit(mutationTypes.filtersFailure)
        })
    })
  },

  [actionTypes.inspections](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.inspectionsStart)
      mapApi
        .getCustomInspectionFields()
        .then((response) => {
          context.commit(
            mutationTypes.inspectionsSuccess,
            response.data.returndata
          )
          resolve(response.data.returndata)
        })
        .catch(() => {
          context.commit(mutationTypes.inspectionsFailure)
        })
    })
  },
}
export default {
  state,
  actions,
  mutations,
  getters,
}
