<template>
  <div>
    <label class="col-form-label" for="typeURL">{{ fieldLabel }}</label>
    <select
      :id="fieldId"
      v-if="fieldType === 'select'"
      :name="fieldLabel"
      class="form-control"
      v-model="currentValue"
    >
      <option
        v-for="(option, index) of options"
        :key="index"
        :value="option.key"
      >
        {{ option.value }}
      </option>
    </select>

    <!-- TEXT -->
    <textarea
      :id="fieldId"
      v-if="fieldType === 'text'"
      :name="fieldLabel"
      class="form-control"
      rows="1"
      v-model="currentValue"
    ></textarea>

    <!-- DATE -->

    <datepicker
      input-class="w-100 form-control bg-white"
      :id="fieldId"
      v-if="fieldType === 'date'"
      placeholder="Select Date"
      :name="fieldLabel"
      v-model="currentValue"
    ></datepicker>

    <!-- NUMBER -->

    <input
      class="form-control"
      :id="fieldId"
      v-if="fieldType === 'number'"
      type="number"
      placeholder="Select Number"
      :name="fieldLabel"
      v-model="currentValue"
    />

    <!-- PHOTO -->

    <input
      :id="fieldId"
      v-if="fieldType === 'photo'"
      type="file"
      class="form-control"
      placeholder="Select image"
      :name="fieldLabel"
    />
  </div>
</template>
<script>
// import {mutationTypes} from '@/store/modules/nav'
// import axios from '@/api/axios'
// import {getItem} from '@/helpers/persistanceStorage'
// import {mapGetters} from 'vuex'
// import {getterTypes} from '@/store/modules/inspections'
// import {actionTypes} from '@/store/modules/inspections'
import Datepicker from 'vuejs-datepicker'
import 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'AppFormField',
  props: ['type', 'options', 'label', 'id', 'fieldValue'],
  data() {
    return {
      fieldType: this.type,
      selectOptions: this.options,
      fieldLabel: this.label,
      fieldId: this.id,
      currentValue: this.fieldValue,
    }
  },
  computed: {
    // ...mapGetters({
    //   inspections: getterTypes.inspections,
    //   isLoading: getterTypes.isLoading,
    // }),
  },

  methods: {},

  mounted() {},
  components: {
    Datepicker,
  },
}
</script>
