import axios from '@/api/axios'
import {getItem} from '@/helpers/persistanceStorage'
let BASE_URL = process.env.VUE_APP_API_BASE_URL

const getFilters = () => {
  const bodyFormData = new FormData()
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  return axios.post(BASE_URL + 'getFilters/', bodyFormData)
}

const getCustomInspectionFields = () => {
  const bodyFormData = new FormData()
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  return axios.post(BASE_URL + 'getCustomInspectionFields/', bodyFormData)
}


export default {
  getFilters,
  getCustomInspectionFields,
}
