<template>
  <div class="bg-light-blue rounded-4 shadow-sm border border-blue border-2 fixTableHead" style="max-height: 100%">
    <!-- DIALOGUE -->

    <transition name="zoom-in">
      <!-- DELETE MODAL -->
      <div
        v-if="showDeleteModal"
        class="bg-white rounded-4"
        style="position: absolute; z-index: 99; width: 100%; height: 100%; --bs-bg-opacity: 0.8"
      >
        <div
          class="row p-2 justify-content-md justify-content-center"
          style="width: 50%; margin: auto; position: relative; top: 25%"
        >
          <div class="bg-white rounded-4 p-4 shadow-sm" style="margin: auto; position: relative; text-align: center">
            <div>
              <h3>Are you sure you want to delete this inspection?</h3>
              <button @click.stop.prevent="onDelete" class="btn btn-secondary m-2 rounded w-auto">Yes</button>
              <button @click.stop.prevent="inspectionDelete" class="btn btn-primary m-2 rounded w-auto">No</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="row mx-0 mb-2 p-2 justify-content-end">
      <div class="col-6 d-flex align-items-center">
        <h5 class="h5 objects-title mb-0">Nen3399</h5>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-end">
          <!-- ADD -->
          <div
            style="cursor: pointer"
            class="border rounded-4 mx-1 button-menu m-3"
            title="Voeg nieuwe Algemene Inspectie toe"
            @click.stop.prevent="newInspection()"
          >
            <a>
              <span class="material-icons" style="color: #212529; margin-left: 24px" role="button"
                >add_circle_outlined</span
              >
            </a>
          </div>

          <!-- EDIT -->
          <div
            style="cursor: pointer"
            class="border rounded-4 mx-1 button-menu m-3"
            title="Bewerk de geselecteerde inspectie"
            @click.stop.prevent="editGeneralClicked()"
          >
            <a>
              <span class="material-icons" style="color: #212529" role="button">edit_location_alt</span>
            </a>
          </div>

          <!-- DELETE -->
          <div
            style="cursor: pointer"
            class="border rounded-4 mx-1 button-menu m-3"
            title="Verwijder de geselecteerde inspectie"
            @click.stop.prevent="inspectionDelete()"
          >
            <a>
              <span class="material-icons" style="color: #212529; margin-left: 24px" role="button"
                >delete_outlined</span
              >
            </a>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: scroll">
      <table class="table table-bordered bg-white" style="border-radius: 20px !important">
        <!-- TABLE -->
        <thead>
          <tr>
            <th>Date</th>
            <th v-for="(obj, index) in tableHeaders" :key="index" scope="col">
              {{ obj.fieldlabel }}
            </th>
          </tr>
        </thead>
        <tbody ref="table">
          <tr
            v-for="(inspection, index) in objectInspections"
            :key="index"
            class="table_row"
            @click.stop="rowClicked(inspection)"
            :ref="inspection.insp_order"
          >
            <td style="background-color: transparent">
              {{ new Date(inspection.date_created).toLocaleDateString('en-US') }}
            </td>
            <td
              style="background-color: transparent"
              v-for="(obj, index) in tableHeaders"
              :key="index"
              v-html="getFieldValue(obj, inspection.data)"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {actionTypes as inspectionActionTypes} from '@/store/modules/inspections'
import {getterTypes as inspectionGetterTypes} from '@/store/modules/inspections'
import {mutationTypes as inspectionsMutationTypes} from '@/store/modules/inspections'
import {mutationTypes as playerMutationTypes} from '@/store/modules/player'
import {getterTypes as mapGetterTypes} from '@/store/modules/map'
import {getItem} from '@/helpers/persistanceStorage'
import {fs} from 'fs'
export default {
  name: 'AppInspection',
  data() {
    return {
      show: false,
      filterName: '',
      dropdown: '',
      options: [],
      isDisabled: false,
      objectInspections: [],
      tableHeaders: [],
      showDeleteModal: false,
      inspection: null,
    }
  },
  computed: {
    ...mapGetters({
      inspections: inspectionGetterTypes.inspections,
      isLoading: inspectionGetterTypes.isLoading,
      generalInspections: inspectionGetterTypes.generalInspections,
      objectClicked: inspectionGetterTypes.objectClicked,
      generalInspectionFields: inspectionGetterTypes.generalInspectionFields,
      newGeneralForm: inspectionGetterTypes.newGeneralForm,
    }),
  },

  watch: {
    newGeneralForm(val) {
      if (!val) {
        if (this.generalInspections.length) {
          this.objectInspections = this.generalInspections.filter((inspection) => {
            return inspection.object_id === this.objectClicked.recid
          })
          if (this.objectInspections.length) {
            setTimeout(() => {
              this.rowClicked(this.objectInspections[this.objectInspections.length - 1])
            }, 100)
          }
        }
      }
    },
    generalInspections() {
      this.objectInspections = this.generalInspections.filter((inspection) => {
        return inspection.object_id === this.objectClicked.recid
      })
    },
    objectClicked(obj) {
      if (obj !== null) {
        this.objectInspections = this.generalInspections.filter((inspection) => {
          return inspection.object_id === this.objectClicked.recid
        })
        if (this.objectInspections.length) {
          this.$nextTick(() => {
            this.rowClicked(this.objectInspections[0])
          })
        }
      }
    },
  },
  methods: {
    newInspection() {
      this.$store.commit(inspectionsMutationTypes.selectedGeneralInspection, null)
      this.$store.commit(inspectionsMutationTypes.newGeneralForm)
    },

    inspectionDelete() {
      this.showDeleteModal = !this.showDeleteModal
    },
    onDelete() {
      const insp = this.inspection
      this.$store.dispatch(inspectionActionTypes.deleteInspection, insp).then(() => {
        this.showDeleteModal = !this.showDeleteModal

        if (this.objectInspections.length) {
          setTimeout(() => {
            this.rowClicked(this.objectInspections[0])
          }, 100)
        } else {
          this.$store.commit(inspectionsMutationTypes.selectedGeneralInspection, null)
        }
      })
    },

    editGeneralClicked() {
      this.$store.commit(inspectionsMutationTypes.editGeneralClicked)
    },
    rowClicked(inspection) {
      this.inspection = inspection
      for (let row of this.$refs.table.rows) {
        row.style.backgroundColor = '#fff'
      }
      this.$nextTick(() => {
        this.$refs[inspection.insp_order][0].style.backgroundColor = '#E8EDF5'
      })

      this.$store.commit(inspectionsMutationTypes.selectedGeneralInspection, inspection.insp_order)
      const domain = getItem('curr-domain')
      let file
      let folder
      let url
      switch (domain) {
        case 'gem-stichtse_vecht':
          folder = inspection.data[696]
          file = inspection.data[697].slice(0, -3)
          url = `riolering/gem-stichtse_vecht/${folder}/${file}mp4`
          break
        case 'gem-venray':
          folder = inspection.data[766]
          file = inspection.data[767].slice(0, -3)
          url = `/riolering/Rijdende Inspectie/Suf 2.0 + 2.1/${folder}/${file}mp4`
          break
        default:
          folder = inspection.data[1021]
          file = inspection.data[1020].slice(0, -3)
          url = `https://riolering.app/riolering/demo_imbor_2024/${folder}/${file}mp4`
          // url = require('@/assets/big_buck_bunny_720p_surround.mp4')
          break
      }
      this.$store.commit(playerMutationTypes.urlChange, url)
    },
    getFilteredTableCells(obj) {
      const arr = []
      for (const [key, value] of Object.entries(obj)) {
        if (this.getFieldValue(key).fieldtype !== 'field_group') {
          arr.push({
            key: key,
            value: value,
          })
        }
      }
      return arr
    },
    getFieldValue(field, inspObj) {
      let empty = ''
      for (const [key, value] of Object.entries(inspObj)) {
        if (key === field.id) {
          if (field.fieldtype === 'select') {
            empty = this.getSelectValue(key, value)
          } else {
            if (value) {
              if (value.url) {
                if (value.url[value.url.length - 1] !== '/') {
                  empty = `<img src="${value.url}" width="120" height="60">`
                }
              } else if (value instanceof File) {
                empty = `<img src="${URL.createObjectURL(value)}" width="120" height="60">`
              } else {
                empty = value
              }
            } else {
              empty = value
            }
          }
        }
      }
      return empty
    },
    getSelectValue(key, value) {
      const inspection = this.generalInspectionFields[1].filter((field) => field.id === key)
      const result = inspection[0].options.filter((option) => option.key === value)
      if (result.length) {
        return result[0].value
      }
    },
  },
  mounted() {
    if (this.generalInspections.length) {
      this.objectInspections = this.generalInspections.filter((inspection) => {
        return inspection.object_id === this.objectClicked.recid
      })

      if (this.objectInspections.length) {
        setTimeout(() => {
          this.rowClicked(this.objectInspections[0])
        }, 100)
      }
    }
    if (this.inspections.length) {
      if (this.generalInspectionFields) {
        this.tableHeaders = this.generalInspectionFields[1].filter((inspection) => {
          return inspection.fieldtype !== 'field_group' && inspection.fieldtype !== 'table_view'
        })
      }
    }
  },
}
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
}

th {
  white-space: nowrap;
  text-align: left;
  background: #ecf1fa;
  position: sticky;
  top: 0px;
}

.fixTableHead {
  border: 1px solid #ddd;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.table_row {
  max-height: 20px;
  cursor: pointer;
}
.zoom-in-enter-active {
  transition: all 0.2s ease 0.2s;
}
.zoom-in-leave-active {
  transition: all 0.2s ease-in;
}
.zoom-in-enter,
.zoom-in-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: scale(0.2);
  opacity: 0;
}
/* .table_row:hover {
  background-color: #eeeeee !important;
} */
</style>
