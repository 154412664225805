<template>
  <div>
    <div
      v-if="isLoading"
      style="
        position: absolute;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden !important;
      "
    ></div>
    <app-nav v-if="!isSignin" class="position-absolute" style="z-index: 1" />
    <router-view></router-view>
  </div>
</template>

<script>
import AppNav from '@/components/nav/Nav.vue'
import {mapGetters} from 'vuex'
import {getterTypes as mapGetterTypes} from '@/store/modules/map'

export default {
  data() {
    return {}
  },
  components: {
    AppNav,
  },
  computed: {
    ...mapGetters({
      isLoading: mapGetterTypes.isLoadingState,
    }),
    isSignin() {
      return this.$route.name === 'login'
    },
  },
}
</script>
