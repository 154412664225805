<template>
  <div
    class="bg-light-blue rounded-4 p-0 shadow-sm border border-blue border-2"
    style="height: 100%; overflow: hidden"
  >
    <div class="row mx-0 mb-2 p-2 border-2 border-bottom justify-content-end">
      <div class="col-6 d-flex align-items-center drag-objects mt-2">
        <h5 class="h5 objects-title mb-0 user-select-none">Objecten</h5>
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-end">
          <!-- <div class="border border-blue rounded-4 p-1 mx-1 button-menu">
            <a href="#">
              <span class="material-icons text-dark">content_paste_search</span>
            </a>
          </div>
          <div class="border border-blue rounded-4 p-1 mx-1 button-menu">
            <a href="#">
              <span class="material-icons text-dark">travel_explore</span>
            </a>
          </div>
          <div class="border border-blue rounded-4 p-1 mx-1 button-menu">
            <a href="#">
              <span class="material-icons text-dark">favorite_border</span>
            </a>
          </div> -->
          <div class="close-button d-flex align-items-center ms-3 mt-2">
            <button @click="close" class="btn p-0 rounded-circle">
              <span class="material-icons text-dark fw-bold">clear</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-white p-2 mt-2 rounded-4 border border-blue border-2 border-start-0 border-end-0"
    >
      <div class="search d-flex align-items"></div>
      <span>{{ objCount }}</span>
      <div id="grid" ref="grid" :style="{height: height, width: width}"></div>
      <!-- <div v-if="isLoading" style="text-align: center; width: 100%">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import {mutationTypes as navMutationTypes} from '@/store/modules/nav'
import {mutationTypes as mapMutationTypes} from '@/store/modules/map'
import {mutationTypes as inspectionMutationTypes} from '@/store/modules/inspections'
import {mutationTypes as playerMutationTypes} from '@/store/modules/player'
import {mapGetters} from 'vuex'
import {getterTypes as mapGetterTypes} from '@/store/modules/map'
import {w2grid, w2utils} from '@/helpers/w2ui/w2ui-2.0.min.js'

export default {
  name: 'AppObjects',
  props: ['features', 'w', 'h'],
  data() {
    return {
      searchQuery: '',
      searchQueryNum: '',
      currentObjId: 0,
      objectsState: [],
      objCount: 0,
      grid: null,
      inputTimeout: null,
      searchData: [],
      width: '370px',
      height: '395px',
    }
  },

  computed: {
    ...mapGetters({
      isLoading: mapGetterTypes.isLoadingState,
      objectClicked: mapGetterTypes.objectClicked,
      filterRemoved: mapGetterTypes.filterRemoved,
    }),
    resultQuery() {
      if (this.searchQuery || this.searchQueryNum) {
        return this.objectsState.filter((item) => {
          return (
            this.searchQuery
              .toLowerCase()
              .split(' ')
              .every((v) => {
                if (v != '') {
                  return item.name.toLowerCase().includes(v)
                } else {
                  return true
                }
              }) &&
            this.searchQueryNum
              .toLowerCase()
              .split(' ')
              .every((v) => {
                if (v != '') {
                  return item.number.toLowerCase().includes(v)
                } else {
                  return true
                }
              })
          )
        })
      } else {
        return this.objectsState
      }
    },
  },
  watch: {
    filterRemoved(val) {
      if (val !== null) {
        this.grid.records = this.grid.records.filter((record) => {
          return record.filters !== val
        })
        this.grid.reload()
        this.objCount = this.grid.records.length
      }
    },
    features: {
      handler: function (objs) {
        this.grid.add(objs)
        this.grid.reload()
        this.setSearches()
        this.objCount = this.grid.records.length
      },
      deep: false,
    },
    objectClicked(obj) {
      if (obj !== null) {
        const id = obj.getId()
        const index = this.grid.get(id, true)
        this.grid.scrollIntoView(index)
        this.grid.selectNone()
        this.grid.select(id)
        this.onObjectClicked({detail: {recid: id}})
      }
    },
    w(val) {
      val
        ? (this.width = Math.round(window.innerWidth * (val / 100)) - 20 + 'px')
        : (this.width = '370px')
    },
    h(val) {
      val
        ? (this.height = window.innerHeight * (val / 100) - 100 + 'px')
        : (this.height = '395px')
    },
  },

  methods: {
    close() {
      this.currentObjId = 0
      this.$store.commit(navMutationTypes.objectChange, false)
      this.$store.commit(inspectionMutationTypes.objectClicked, null)
    },
    onObjectClicked(e) {
      const record = this.grid.get(e.detail.recid)
      if (this.currentObjId !== e.detail.recid) {
        this.currentObjId = e.detail.recid
        this.$store.commit(
          inspectionMutationTypes.selectedGeneralInspection,
          null
        )
        this.$store.commit(playerMutationTypes.urlChange, null)
        this.$store.commit(playerMutationTypes.imageChange, null)
        this.$store.commit(inspectionMutationTypes.objectClicked, record)
        this.$store.commit(mapMutationTypes.pointerPos, null)
      }
    },
    filterValue(e) {
      const input = document.getElementById(e.target.id)
      clearTimeout(this.inputTimeout)
      this.inputTimeout = setTimeout(() => {
        if (e.target.value === '') {
          this.searchData = this.searchData.filter(
            (d) => d.field !== e.target.id
          )
          this.grid.search(this.searchData)
        } else {
          const existField = this.searchData.find(
            (d) => d.field === e.target.id
          )
          if (existField) {
            existField.value = e.target.value
          } else {
            this.searchData.push({
              field: e.target.id,
              value: e.target.value,
              operator: 'begins with',
            })
          }
          this.grid.search(this.searchData)
        }
        this.searchData.forEach((d) => {
          const el = document.getElementById(d.field)
          el.value = d.value
        })
        this.setSearches(input.id)
      }, 500)
    },
    setSearches(id) {
      document.querySelectorAll('.searchInput').forEach((el) => {
        el.addEventListener('input', this.filterValue)
      })
      if (id) {
        const input = document.getElementById(id)
        input.focus()
      }
    },
  },
  mounted() {
    let columns = []
    const fields = ['name', 'number']
    this.fields = fields
    fields.forEach((f) => {
      const html = `<input class='searchInput' style='width: 100%' id='${f}' placeholder='${
        f.charAt(0).toUpperCase() + f.slice(1)
      }'></input>`
      columns.push({field: f, text: html, size: '50%'})
    })
    this.grid = new w2grid({
      name: 'grid',
      box: '#grid',
      columns,
      records: [],
      autoLoad: true,
      sortable: true,
      onClick: this.onObjectClicked,
      searchData: this.searchData,
    })
    if (this.objectClicked) {
      this.currentObjId = this.objectClicked.id
    }
  },
}
</script>
<style scoped>
.active {
  background-color: #ecf1fa;
}
</style>
