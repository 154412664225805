import inspectionsApi from '@/api/inspections'
import {setItem} from '@/helpers/persistanceStorage'
const state = {
  inspections: [],
  isLoading: false,
  formValues: [],
  customInspection: [],
  generalInspections: [],
  generalInspectionFields: [],
  subInspectionFields: [],
  save: null,
  inspectionFiles: [],
  selectedGeneralInspection: null,
  selectedCustomInspection: null,
  editSubClicked: false,
  newClicked: false,
  saveGeneralForm: false,
  editGeneralClicked: false,
  newGeneralForm: false,
  editGeneralForm: false,
  newInspectionClicked: false,
  generalInspectionSaved: false,
  subInspectionName: '',
  generalInspectionName: '',
  objectClicked: {
    filters: '',
    name: '',
    number: '',
    recid: '',
  },
}
export const mutationTypes = {
  getGeneralInspectionsStart: '[inspections] GetGeneralInspectionsStart',
  getGeneralInspectionsSuccess: '[inspections] GetGeneralInspectionsSuccess',
  getGeneralInspectionsFailure: '[inspections] GetGeneralInspectionsFailure',

  inspectionDeleteStart: '[inspections] inspectionDeleteStart',
  inspectionDeleteSuccess: '[inspections] inspectionDeleteSuccess',
  inspectionDeleteFailure: '[inspections] inspectionDeleteFailure',

  getInspectionFilesStart: '[inspections] GetInspectionFilesStart',
  getInspectionFilesSuccess: '[inspections]  GetInspectionFilesSuccess',
  getInspectionFilesFailure: '[inspections]  GetInspectionFilesFailure',

  inspectionsStart: '[inspections] inspectionsStar',
  inspectionsSuccess: '[inspections] inspectionsSuccess',
  inspectionsFailure: '[inspections] inspectionsFailure',

  addInspectionStart: '[inspections] AddInspectionStart',
  addInspectionSuccess: '[inspections] AddInspectionSuccess',
  addInspectionFailure: '[inspections] AddInspectionFailure',

  editInspectionStart: '[inspections] EditInspectionStart',
  editInspectionSuccess: '[inspections] EditInspectionSuccess',
  editInspectionFailure: '[inspections] EditInspectionFailure',

  getCustomInspectionStart: '[inspections] GetCustomInspectionStart',
  getCustomInspectionSuccess: '[inspections] GetCustomInspectionSuccess',
  getCustomInspectionFailure: '[inspections] GetCustomInspectionFailure',

  getVideoStart: '[inspections] GetVideoStart',
  getVideoSuccess: '[inspections] GetVideoSuccess',
  getVideoFailure: '[inspections] GetVideoFailure',

  addCustomInspection: '[inspections] AddCustomInspection',
  editCustomInspection: '[inspections] EditCustomInspection',

  addGeneralInspection: '[inspections] AddGeneralInspection',
  editGeneralInspection: '[inspections] EditGeneralInspection',

  save: '[inspections] Save',
  formValues: '[inspections] FormValues',
  selectedGeneralInspection: '[inspections] SelectedGeneralInspection',
  selectedCustomInspection: '[inspections] SelectedCustomInspection',
  editSubClicked: '[inspections] editSubClicked',
  newClicked: '[inspections] newClicked',
  saveGeneralForm: '[inspections] saveGeneralForm',
  newGeneralForm: '[inspections] newGeneralForm',
  newInspectionClicked: '[inspections] newInspectionClicked',
  generalInspectionSaved: '[inspections] generalInspectionSaved',
  editGeneralForm: '[inspections] editGeneralForm',
  editGeneralClicked: '[inspections] editGeneralClicked',
  objectClicked: '[inspections] objectClicked',
}
export const actionTypes = {
  getInspections: '[inspections] getInspections',
  getGeneralInspections: '[inspections] GetGeneralInspections',
  getCustomInspection: '[inspections] GetCustomInspections',
  addInspection: '[inspections] AddInspection',
  deleteInspection: '[inspections] DeleteInspection',
  editInspection: '[inspections] EditInspection',
  getInspectionFiles: '[inspections] GetInspectionFiles',
  getVideo: '[inspections] GetVideo',
}

export const getterTypes = {
  subInspectionName: '[inspections] subInspectionName',
  generalInspectionName: '[inspections] generalInspectionName',
  selectedCustomInspection: '[inspections] SelectedCustomInspection',
  selectedGeneralInspection: '[inspections] selectedGeneralInspection',
  save: '[inspections] Save',
  inspections: '[inspections] Inspections',
  isLoading: '[inspections] isLoading',
  formValues: '[inspections] FormValues',
  customInspection: '[inspections] CustomInspections',
  generalInspections: '[inspections] GeneralInspections',
  inspectionFiles: '[inspections] InspectionFiles',
  generalInspectionFields: '[inspections] GeneralInspectionFields',
  customInspectionFields: '[inspections] CustomInspectionFields',
  editSubClicked: '[inspections] editSubClicked',
  newClicked: '[inspections] NewClicked',
  saveGeneralForm: '[inspections] saveGeneralForm',
  editGeneralForm: '[inspections] editGeneralForm',
  newGeneralForm: '[inspections] newGeneralForm',
  newInspectionClicked: '[inspections] newInspectionClicked',
  generalInspectionSaved: '[inspections] generalInspectionSaved',
  editGeneralClicked: '[inspections] editGeneralClicked',
  subInspectionFields: '[inspections] SubInspectionFields',
  objectClicked: '[inspections] objectClicked',
}

const getters = {
  [getterTypes.objectClicked]: (state) => {
    return state.objectClicked
  },
  [getterTypes.subInspectionName]: (state) => {
    return state.subInspectionName
  },
  [getterTypes.generalInspectionName]: (state) => {
    return state.generalInspectionName
  },
  [getterTypes.generalInspectionFields]: (state) => {
    let group_arr = []
    let fields_arr = []
    let arr = state.inspections.filter((data) => {
      return data.id === '79'
    })
    if (arr.length) {
      state.generalInspectionName = arr[0].name
      arr[0].fields.forEach((obj) => {
        if (obj.fieldtype === 'field_group' || obj.fieldtype === 'table_view') {
          group_arr.push(obj)
        } else {
          fields_arr.push(obj)
        }
      })
      const options = []
      group_arr.map((field) => {
        if (field.options !== undefined) {
          options.push(...field.options)
        }
      })
      const checkIfExist = (id) => {
        let exist = options.filter((option) => {
          return option.key === id
        })
        if (exist.length) {
          return true
        } else {
          return false
        }
      }
      let final_group_arr = []
      group_arr.map((group) => {
        if (checkIfExist(group.id)) {
          fields_arr.push(group)
        } else {
          final_group_arr.push(group)
        }
      })

      state.generalInspectionFields = [final_group_arr, fields_arr]
      return state.generalInspectionFields
    }
  },
  [getterTypes.customInspectionFields]: (state) => {
    let group_arr = []
    let fields_arr = []
    let arr = state.inspections.filter((data) => {
      return data.id === '80'
    })
    if (arr.length) {
      arr[0].fields.forEach((obj) => {
        if (obj.fieldtype === 'field_group' || obj.fieldtype === 'table_view') {
          group_arr.push(obj)
        } else {
          fields_arr.push(obj)
        }
      })
      const options = []
      group_arr.map((field) => {
        if (field.options !== undefined) {
          options.push(...field.options)
        }
      })
      const checkIfExist = (id) => {
        let exist = options.filter((option) => {
          return option.key === id
        })
        if (exist.length) {
          return true
        } else {
          return false
        }
      }
      let final_group_arr = []
      group_arr.map((group) => {
        if (checkIfExist(group.id)) {
          fields_arr.push(group)
        } else {
          final_group_arr.push(group)
        }
      })

      state.customInspectionFields = [final_group_arr, fields_arr]
      return state.customInspectionFields
    }
  },
  [getterTypes.subInspectionFields]: (state) => {
    let group_arr = []
    let fields_arr = []
    let arr = state.inspections.filter((data) => {
      return data.id === '80'
    })
    state.subInspectionName = arr[0].name

    arr[0].fields.forEach((obj) => {
      if (obj.fieldtype === 'field_group' || obj.fieldtype === 'table_view') {
        group_arr.push(obj)
      } else {
        fields_arr.push(obj)
      }
    })
    const options = []
    group_arr.map((field) => {
      if (field.options !== undefined) {
        options.push(...field.options)
      }
    })
    const checkIfExist = (id) => {
      let exist = options.filter((option) => {
        return option.key === id
      })
      if (exist.length) {
        return true
      } else {
        return false
      }
    }
    let final_group_arr = []
    group_arr.map((group) => {
      if (checkIfExist(group.id)) {
        fields_arr.push(group)
      } else {
        final_group_arr.push(group)
      }
    })
    state.subInspectionFields = [final_group_arr, fields_arr]
    return state.subInspectionFields
  },
  [getterTypes.inspectionFiles]: (state) => {
    return state.inspectionFiles
  },

  [getterTypes.editGeneralForm]: (state) => {
    return state.editGeneralForm
  },
  [getterTypes.newGeneralForm]: (state) => {
    return state.newGeneralForm
  },
  [getterTypes.newInspectionClicked]: (state) => {
    return state.newInspectionClicked
  },
  [getterTypes.generalInspectionSaved]: (state) => {
    return state.generalInspectionSaved
  },

  [getterTypes.editGeneralClicked]: (state) => {
    return state.editGeneralClicked
  },
  [getterTypes.inspections]: (state) => {
    return state.inspections
  },
  [getterTypes.isLoading]: (state) => {
    return state.isLoading
  },

  [getterTypes.selectedGeneralInspection]: (state) => {
    return state.selectedGeneralInspection
  },
  [getterTypes.selectedCustomInspection]: (state) => {
    return state.selectedCustomInspection
  },

  [getterTypes.formValues]: (state) => {
    return state.formValues
  },
  [getterTypes.customInspection]: (state) => {
    return state.customInspection
  },
  [getterTypes.generalInspections]: (state) => {
    return state.generalInspections
  },
  [getterTypes.save]: (state) => {
    return state.save
  },

  [getterTypes.editSubClicked]: (state) => {
    return state.editSubClicked
  },
  [getterTypes.newClicked]: (state) => {
    return state.newClicked
  },
  [getterTypes.saveGeneralForm]: (state) => {
    return state.saveGeneralForm
  },
}

const mutations = {
  [mutationTypes.objectClicked](state, payload) {
    state.objectClicked = payload
  },
  [mutationTypes.editGeneralClicked](state) {
    state.editGeneralClicked = !state.editGeneralClicked
  },

  [mutationTypes.editGeneralForm](state) {
    state.editGeneralForm = !state.editGeneralForm
  },
  [mutationTypes.newGeneralForm](state) {
    state.newGeneralForm = !state.newGeneralForm
  },
  [mutationTypes.newInspectionClicked](state) {
    state.newInspectionClicked = !state.newInspectionClicked
  },
  [mutationTypes.generalInspectionSaved](state, payload) {
    state.generalInspectionSaved = payload
  },

  [mutationTypes.editSubClicked](state) {
    state.editSubClicked = !state.editSubClicked
  },
  [mutationTypes.newClicked](state) {
    state.newClicked = !state.newClicked
  },
  [mutationTypes.saveGeneralForm](state) {
    state.saveGeneralForm = !state.saveGeneralForm
  },
  [mutationTypes.selectedGeneralInspection](state, payload) {
    state.selectedGeneralInspection = payload
  },
  [mutationTypes.selectedCustomInspection](state, payload) {
    state.selectedCustomInspection = payload
  },
  [mutationTypes.save](state, payload) {
    state.save = payload
  },

  [mutationTypes.inspectionsStart](state) {
    // state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.inspectionsSuccess](state, payload) {
    // state.isLoading = false
    state.inspections = payload
  },
  [mutationTypes.inspectionsFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },

  [mutationTypes.inspectionDeleteStart](state) {
    state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.inspectionDeleteSuccess](state, payload) {
    state.isLoading = false
    let inspArr = []
    if (payload.general_inspection) {
      inspArr = state.customInspection
    } else {
      inspArr = state.generalInspections
    }
    let index = 0
    for (let i = 0; i < inspArr.length; i++) {
      if (inspArr[i].insp_order === payload.insp_order) {
        index = i
      }
    }
    if (payload.general_inspection) {
      state.customInspection.splice(index, 1)
    } else {
      state.generalInspections.splice(index, 1)
    }
  },
  [mutationTypes.inspectionDeleteFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },

  [mutationTypes.getCustomInspectionStart](state) {
    // state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.getCustomInspectionSuccess](state, payload) {
    // state.isLoading = false
    state.customInspection = payload
  },
  [mutationTypes.getCustomInspectionFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },
  [mutationTypes.getGeneralInspectionsStart](state) {
    state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.getGeneralInspectionsSuccess](state, payload) {
    state.isLoading = false
    state.generalInspections = payload
  },
  [mutationTypes.getGeneralInspectionsFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },

  [mutationTypes.getInspectionFilesStart](state) {
    state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.getInspectionFilesSuccess](state, payload) {
    state.isLoading = false
    state.inspectionFiles = payload
  },
  [mutationTypes.getInspectionFilesFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },

  [mutationTypes.getVideoStart](state) {
    state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.getVideoSuccess](state, payload) {
    state.isLoading = false
  },
  [mutationTypes.getVideoFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },

  [mutationTypes.addCustomInspection](state, payload) {
    state.customInspection = [...state.customInspection, payload]
  },
  [mutationTypes.addGeneralInspection](state, payload) {
    state.generalInspections = [...state.generalInspections, payload]
  },

  [mutationTypes.editCustomInspection](state, payload) {
    const newState = state.customInspection.map((inspection) => {
      if (inspection.insp_order === payload[0]) {
        inspection.data = payload[1]
      }
      return inspection
    })
    state.customInspection = newState

    // state.customInspection = [...state.customInspection, payload]
  },
  [mutationTypes.editGeneralInspection](state, payload) {
    state.generalInspections.filter((inspection) => {
      if (inspection.insp_order === payload.insp_order) {
        inspection.data = payload.data
      }
    })
  },

  [mutationTypes.addInspectionStart](state) {
    state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.addInspectionSuccess](state, payload) {
    state.isLoading = false
  },
  [mutationTypes.addInspectionFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },

  [mutationTypes.editInspectionStart](state) {
    state.isLoading = true
    state.validationErrors = null
  },
  [mutationTypes.editInspectionSuccess](state, payload) {
    state.isLoading = false
  },
  [mutationTypes.editInspectionFailure](state, payload) {
    state.isLoading = false
    state.validationErrors = payload
  },
  [mutationTypes.formValues](state, payload) {
    state.formValues = payload
  },
}
const actions = {
  [actionTypes.getGeneralInspections](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getGeneralInspectionsStart)
      inspectionsApi
        .getGeneralInspections()
        .then((response) => {
          const arr = response.data.returndata
          context.commit(
            mutationTypes.getGeneralInspectionsSuccess,
            response.data.returndata
          )
          resolve(response.data.returndata)
        })

        .catch(() => {
          context.commit(mutationTypes.getGeneralInspectionsFailure)
        })
    })
  },

  [actionTypes.getInspections](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.inspectionsStart)
      inspectionsApi
        .getCustomInspectionFields()
        .then((response) => {
          context.commit(
            mutationTypes.inspectionsSuccess,
            response.data.returndata
          )
          resolve(response.data.returndata)
        })
        .catch((result) => {
          context.commit(mutationTypes.inspectionsFailure, result)
        })
    })
  },

  [actionTypes.getCustomInspection](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getCustomInspectionStart)
      inspectionsApi
        .getCustomInspection()
        .then((response) => {
          context.commit(
            mutationTypes.getCustomInspectionSuccess,
            response.data.returndata
          )
          resolve(response.data.returndata)
        })

        .catch(() => {
          context.commit(mutationTypes.getCustomInspectionFailure)
        })
    })
  },

  [actionTypes.addInspection](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addInspectionStart)
      inspectionsApi
        .addInspection(data)
        .then((response) => {
          context.commit(
            mutationTypes.addInspectionSuccess,
            response
            //response.data.returndata[1]
          )
          resolve(response)
        })

        .catch(() => {
          context.commit(mutationTypes.addInspectionFailure)
        })
    })
  },

  [actionTypes.getInspectionFiles](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getInspectionFilesStart)
      inspectionsApi
        .getInspectionFiles(data)
        .then((response) => {
          context.commit(
            mutationTypes.getInspectionFilesSuccess,
            response.data.list
          )
          resolve(response.data.list)
        })

        .catch(() => {
          context.commit(mutationTypes.getInspectionFilesFailure)
        })
    })
  },

  [actionTypes.deleteInspection](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.inspectionDeleteStart)
      inspectionsApi
        .deleteInspection(data)
        .then((response) => {
          context.commit(mutationTypes.inspectionDeleteSuccess, data)
          resolve(response)
        })

        .catch((error) => {
          context.commit(mutationTypes.inspectionDeleteFailure, error)
        })
    })
  },

  [actionTypes.editInspection](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.editInspectionStart)
      inspectionsApi
        .editInspection(data)
        .then((response) => {
          context.commit(
            mutationTypes.editInspectionSuccess,
            response
            //response.data.returndata[1]
          )
          resolve(response)
        })

        .catch(() => {
          context.commit(mutationTypes.editInspectionFailure)
        })
    })
  },

  [actionTypes.getVideo](context, url) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getVideoStart)
      inspectionsApi
        .getVideo(url)
        .then((response) => {
          context.commit(mutationTypes.getVideoSuccess, [response])
          resolve(response)
        })
        .catch((result) => {
          context.commit(mutationTypes.getVideoFailure, result)
        })
    })
  },
}
export default {
  state,
  actions,
  mutations,
  getters,
}
