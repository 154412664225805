<template>
  <div
    class="row justify-content-center"
    style="background: rgba(255, 255, 255, 0.75); min-width: 100%; min-height: 100%"
  >
    <!-- DIALOGUE WINDOW -->
    <transition name="zoom-in">
      <div
        v-if="dialogue"
        class="position-absolute overflow-hidden row justify-content-center"
        style="background: rgba(255, 255, 255, 0.75); min-width: 100%; min-height: 100%; z-index: 99"
      >
        <div
          class="bg-white rounded-4 p-4 shadow-sm border border-2"
          style="margin-top: 300px; text-align: center; max-width: 350px; max-height: 250px"
        >
          <h3>Do you really want to close this Sub Inspection without saving?</h3>
          <div>
            <button @click.stop.prevent="approveClose()" class="btn btn-secondary m-2 rounded w-auto">Yes</button>
            <button @click.stop.prevent="dissaproveClose()" class="btn btn-primary m-2 rounded w-auto">No</button>
          </div>
        </div>
      </div>
    </transition>
    <!-- NEW SUB INSPECTION -->
    <transition name="zoom-in">
      <div
        v-if="newClicked"
        class="position-absolute overflow-hidden row justify-content-center"
        style="background: rgba(255, 255, 255, 0.75); min-width: 100%; min-height: 100%; z-index: 99"
      >
        <div
          class="bg-white rounded-4 p-4 shadow-sm border border-2"
          style="margin-top: 50px; text-align: center; max-width: 80%; height: 85vh"
        >
          <app-sub-form v-bind:new="false" style="max-height: 65vh"></app-sub-form>
        </div>
      </div>
    </transition>
    <!-- EDIT SUB INSPECTION -->
    <transition name="zoom-in">
      <div
        v-if="editSubClicked"
        class="position-absolute overflow-hidden row justify-content-center"
        style="background: rgba(255, 255, 255, 0.75); min-width: 100%; min-height: 100%; z-index: 99"
      >
        <div
          class="bg-white rounded-4 p-4 shadow-sm border border-2"
          style="margin-top: 80px; text-align: center; max-width: 80%; height: 85vh"
        >
          <app-edit-sub-form></app-edit-sub-form>
        </div>
      </div>
    </transition>
    <!-- LOADER -->
    <div
      v-if="isLoading"
      class="text-center position-absolute overflow-hidden"
      style="z-index: 99; background: rgba(255, 255, 255, 0.75); min-width: 100%; min-height: 100%"
    >
      <div class="lds-ellipsis" style="margin-top: 50vh">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <!-- TABS -->
    <div
      class="bg-white rounded-4 p-4 shadow-sm border border-2 position-relative col-md-10"
      style="height: 90vh; margin-top: 2%; overflow: hidden"
    >
      <div class="close-button d-flex align-items-end">
        <div style="width: 100%">
          <button class="btn p-0 rounded-circle" @click.prevent.stop="onClose" style="position: relative; left: 98%">
            <span class="material-icons text-dark fw-bold">clear</span>
          </button>
        </div>
      </div>
      <div class="row" content-class="mt-3 justified">
        <!-- GENERAL INSPECTION -->
        <div
          class="col-md-6"
          style="height: 100vh"
          :title="generalInspectionName ? generalInspectionName : 'General Inspection'"
          active
        >
          <form ref="form" enctype="multipart/form-data" class="bg-white p-4" style="overflow-y: scroll; height: 70%">
            <div class="row">
              <div class="col">
                <div style="max-height: 65vh">
                  <div
                    v-for="group in generalInspectionFields[0]"
                    :key="group.id"
                    class="form-group mb-3 p-3 rounded-4 border p-4"
                  >
                    <h5 class="h5 objects-title mb-0">
                      {{ group.fieldlabel }}
                    </h5>

                    <div v-if="group.fieldtype === 'field_group'" class="row">
                      <div v-for="(object, index) in group.options" :key="index" class="col-md-6">
                        <app-form-field
                          v-if="getGeneralInspectionFieldValue(object.key).fieldtype !== 'field_group'"
                          v-bind:id="object.key"
                          v-bind:type="getGeneralInspectionFieldValue(object.key).fieldtype"
                          v-bind:options="getGeneralInspectionFieldValue(object.key).options"
                          v-bind:label="getGeneralInspectionFieldValue(object.key).fieldlabel"
                          v-bind:fieldValue="getValue(object)"
                          v-model="getGeneralInspectionFieldValue(object.key).fieldlabel"
                        ></app-form-field>
                        <div
                          v-if="getGeneralInspectionFieldValue(object.key).fieldtype === 'field_group'"
                          class="form-group mb-3 p-3 rounded-3 border"
                        >
                          <h3>
                            {{ getGeneralInspectionFieldValue(object.key).fieldlabel }}
                          </h3>

                          <div v-for="(obj, index) in getGeneralInspectionFieldValue(object.key).options" :key="index">
                            <app-form-field
                              v-bind:id="obj.key"
                              v-bind:type="getGeneralInspectionFieldValue(obj.key).fieldtype"
                              v-bind:options="getGeneralInspectionFieldValue(obj.key).options"
                              v-bind:label="getGeneralInspectionFieldValue(obj.key).fieldlabel"
                              v-model="getGeneralInspectionFieldValue(obj.key).fieldlabel"
                            ></app-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="group.fieldtype === 'table_view'">
                      <table
                        class="table table-bordered table-responsive bg-white w-100"
                        style="border-radius: 20px !important"
                      >
                        <thead>
                          <tr>
                            <th v-for="(field, index) in group.options" :key="index" scope="col">
                              {{ getGeneralInspectionFieldValue(field.key).fieldlabel }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in group.rows" :key="index" style="height: 30px">
                            <td v-for="(field, index) in group.options" :key="index">
                              <app-form-field
                                v-bind:id="field.key"
                                v-bind:type="getGeneralInspectionFieldValue(field.key).fieldtype"
                                v-bind:options="getGeneralInspectionFieldValue(field.key).options"
                                v-bind:label="getGeneralInspectionFieldValue(field.key).fieldlabel"
                                v-model="getGeneralInspectionFieldValue(field.key).fieldlabel"
                              ></app-form-field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- SUB-INSPECTION -->
        <div class="col-md-6 mt-4">
          <app-sub-inspections style="overflow: hidden; height: 70%"> </app-sub-inspections>
        </div>
      </div>
    </div>
    <!-- BUTTONS -->
    <div class="row p-2 justify-content-center position-absolute" style="top: 85%">
      <div class="col-md-6">
        <button @click.stop.prevent="onClose" class="btn btn-secondary m-2 rounded w-auto">Close</button>
        <button @click.stop.prevent="onSave" class="btn btn-primary m-2 rounded w-auto">Save</button>
      </div>
      <div class="col-md-6 w-100"></div>
    </div>
  </div>
</template>
<script>
// import {mutationTypes} from '@/store/modules/nav'
// import axios from '@/api/axios'
// import {getItem} from '@/helpers/persistanceStorage'
import uniqid from 'uniqid'
import {mapGetters} from 'vuex'
import {getterTypes as inspectionGetterTypes} from '@/store/modules/inspections'
import {getterTypes as playerGetterTypes} from '@/store/modules/player'
import {getterTypes as mapGetterTypes} from '@/store/modules/map'
import {actionTypes as inspectionActionTypes} from '@/store/modules/inspections'
import {mutationTypes as inspectionsMutationTypes} from '@/store/modules/inspections'

// import {actionTypes} from '@/store/modules/inspections'
import AppFormField from '@/components/inspections/Field.vue'
import AppSubForm from '@/components/inspections/SubForm.vue'
import AppEditSubForm from '@/components/inspections/EditSubForm.vue'

import AppSubInspections from '@/components/inspections/SubInspections.vue'
export default {
  name: 'EditGeneralForm',

  data() {
    return {
      fields: [],
      imageStr: '',
      imageFile: null,
      showImage: false,
      subInspections: [{id: uniqid(), new: false}],
      dialogue: false,
      closeId: '',
      saveOver: false,
      myInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      generalInspectionFields: inspectionGetterTypes.generalInspectionFields,
      subInspectionFields: inspectionGetterTypes.subInspectionFields,
      inspections: inspectionGetterTypes.inspections,
      isLoading: inspectionGetterTypes.isLoading,
      image: playerGetterTypes.image,
      url: playerGetterTypes.url,
      pointerPos: mapGetterTypes.pointerPos,
      objectClicked: inspectionGetterTypes.objectClicked,
      generalInspectionName: inspectionGetterTypes.generalInspectionName,
      generalInspections: inspectionGetterTypes.generalInspections,
      subInspectionName: inspectionGetterTypes.subInspectionName,
      newClicked: inspectionGetterTypes.newClicked,
      editSubClicked: inspectionGetterTypes.editSubClicked,
      selectedGeneralInspection: inspectionGetterTypes.selectedGeneralInspection,
    }),
    generalInspection() {
      let currInspArr = this.generalInspections.filter((inspection) => {
        return inspection.insp_order === this.selectedGeneralInspection
      })
      return currInspArr[0]
    },
  },

  methods: {
    getValue(field) {
      let empty = ''
      for (const [key, value] of Object.entries(this.generalInspection.data)) {
        if (key === field.key) {
          empty = value
        }
      }
      return empty
    },
    approveClose() {
      for (let i = 0; i < this.subInspections.length; i++) {
        if (this.subInspections[i].id === this.closeId) {
          this.subInspections.splice(i, 1)
        }
      }
      this.dialogue = false
    },
    dissaproveClose() {
      this.dialogue = false
    },
    closeSubInspection(id) {
      this.closeId = id
      this.dialogue = true
    },
    newTab() {
      this.subInspections.push({id: uniqid(), new: true})
    },
    onRemoveImage() {
      this.showImage = false
    },
    onClose() {
      this.$store.commit(inspectionsMutationTypes.editGeneralForm)
      clearInterval(this.myInterval)
    },

    onSave() {
      const result = []
      let inspection_fields = {}
      for (var i = 0; i < this.$refs.form.length; i++) {
        let value = null
        value = this.$refs.form[i].value
        inspection_fields[this.$refs.form[i].id] = this.$refs.form[i].value
        result.push({
          type: this.fieldTypeConverter(this.$refs.form[i].type),
          value: value,
          row_id: this.$refs.form[i].id,
          id: uniqid(),
        })
      }
      const newInspection = {
        date_created: new Date(),
        data: inspection_fields,
        object_id: this.objectClicked.recid,
        insp_order: this.selectedGeneralInspection,
      }

      this.$store
        .dispatch(inspectionActionTypes.addInspection, [
          this.objectClicked.recid,
          result,
          '79',
          '0',
          'edit',
          this.selectedGeneralInspection,
        ])
        .then((r) => {
          this.$store.commit(inspectionsMutationTypes.editGeneralInspection, newInspection)
          this.$store.commit(inspectionsMutationTypes.save, this.selectedGeneralInspection)
        })
    },
    fieldTypeConverter(field) {
      switch (field) {
        case 'textarea':
        case 'text':
          return 'text'
        case 'number':
          return 'number'
        case 'select-one':
          return 'select'
        case 'file':
          return 'photo'
      }
    },
    getGeneralInspectionFieldValue(id) {
      const objectArr = this.generalInspectionFields[1].filter((inspection) => inspection.id === id)
      const testObj = {
        fieldtype: 'text',
        fieldlabel: id,
      }
      if (objectArr[0]) {
        const obj = objectArr[0]
        if (obj.fieldtype && obj.fieldlabel) {
          return obj
        } else {
          return testObj
        }
      } else {
        return testObj
      }
    },
    getSubInspectionFieldValue(id) {
      const objectArr = this.subInspectionFields[1].filter((inspection) => inspection.id === id)
      const testObj = {
        fieldtype: 'text',
        fieldlabel: id,
      }
      if (objectArr[0]) {
        const obj = objectArr[0]
        if (obj.fieldtype && obj.fieldlabel) {
          return obj
        } else {
          return testObj
        }
      } else {
        return testObj
      }
    },
    Base64ToImage(base64img, callback) {
      var img = new Image()
      img.onload = function () {
        callback(img)
      }
      img.src = base64img
    },
    checkToClose() {
      this.myInterval = setInterval(() => {
        if (!this.saveOver) {
          this.onClose()
        }
      }, 500)
    },
  },
  components: {
    AppFormField,
    AppSubForm,
    AppSubInspections,
    AppEditSubForm,
  },
  watch: {
    image(val) {
      this.imageStr = val
    },
    showImage(val) {
      if (!val) {
        const groups = this.$refs.form.getElementsByClassName('form-group')
        groups[7].style.visibility = 'visible'
        groups[7].style.position = 'relative'
      }
    },
    isLoading(val) {
      this.saveOver = val
    },
  },

  mounted() {
    if (this.url) {
      this.showImage = true
    }

    this.imageStr = this.image
    const url = this.image
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        this.imageFile = new File([blob], 'Screenshot.png', {type: 'image/png'})
      })
  },
}
</script>
<style>
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: 0;
}
.zoom-in-enter-active {
  transition: all 0.2s ease 0.2s;
}
.zoom-in-leave-active {
  transition: all 0.2s ease-in;
}
.zoom-in-enter,
.zoom-in-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: scale(0.2);
  opacity: 0;
}
</style>
