import axios from '@/api/axios'
let BASE_URL = process.env.VUE_APP_API_BASE_URL
const register = (credentials) => {
  return this.$axios.post('/users', {user: credentials})
}
const signIn = (credentials) => {
  const bodyFormData = new FormData()
  bodyFormData.set('username', credentials.username)
  bodyFormData.set('password', credentials.password)
  bodyFormData.set('version', credentials.version)
  bodyFormData.set('domain', credentials.domain)
  return axios.post(BASE_URL + 'login/', bodyFormData)
}

const getCurrentUser = () => {
  return axios.get(BASE_URL + 'user')
}

export default {
  register,
  signIn,
  getCurrentUser,
}
