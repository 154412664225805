import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/styles/style.css'
import VueScreen from 'vue-screen'
import App from './App.vue'
import router from './router'
import store from './store'
import VueDraggableResizable from 'vue-draggable-resizable'
import VueCookies from 'vue-cookies'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.use(VueCookies, {expire: '1min'})
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.use(VueScreen, {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1324,
  xxl: 1600,
  xxxl: 2000,
})
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyADZ6YyY7YOXZp3YIHdiepoJQhGLf51bIU',
//     libraries: 'places',
//   },
// })
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
