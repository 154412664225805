<template>
  <div
    class="float-lg-end float-sm-start rounded-4 bg-dark p-2 d-flex justify-content-center mx-0 object-menu"
  >
    <div
      style="cursor: pointer"
      @click="onObjectMenuClick"
      class="border border-white rounded-4 mx-1 button-menu"
      v-bind:class="{active: objectState}"
      title="Objecten"
    >
      <a>
        <span class="material-icons text-white" role="button">view_list</span>
      </a>
    </div>
    <div
      style="cursor: pointer"
      @click="onLegendaMenuClick"
      class="border border-white rounded-4 mx-1 button-menu"
      v-bind:class="{active: legendaState}"
      title="Filters"
    >
      <a href="#">
        <span class="material-icons text-white">account_tree</span>
      </a>
    </div>
    <div
      style="cursor: pointer"
      @click="onLogout"
      class="border border-white rounded-4 mx-1 button-menu"
      title="Log uit"
    >
      <a href="#">
        <span class="material-icons text-white">logout</span>
      </a>
    </div>

    <!-- <div
      style="cursor: pointer"
      @click="onInspectieMenuClick"
      class="border border-white rounded-4 mx-1 button-menu"
      v-bind:class="{active: inspectionsState}"
    >
      <a href="#">
        <span class="material-icons text-white">place</span>
      </a>
    </div> -->
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {getterTypes} from '@/store/modules/nav'
import {mutationTypes as authMutationTypes} from '@/store/modules/auth'
import {mutationTypes} from '@/store/modules/nav'
import {mutationTypes as mapMutationTypes} from '@/store/modules/map'
import {actionTypes} from '@/store/modules/inspections'
export default {
  name: 'AppObjectMenu',
  methods: {
    onObjectMenuClick() {
      if (this.objectClicked) {
        this.$store.commit(mutationTypes.objectChange, false)
      } else {
        this.$store.commit(mutationTypes.objectChange, true)
      }
    },
    onLegendaMenuClick() {
      if (this.legendaClicked) {
        this.$store.commit(mutationTypes.legendaChange, false)
      } else {
        this.$store.commit(mutationTypes.legendaChange, true)
      }
    },
    onInspectieMenuClick() {
      this.$store.commit(mutationTypes.inspectionsChange, true)
    },
    onLogout() {
      // localStorage.removeItem('accessToken')
      this.$store.commit(authMutationTypes.hasRights, false)
      this.$cookies.remove('accessToken')
      this.$router.push({name: 'login'})
    },
  },
  computed: {
    ...mapGetters({
      objectState: getterTypes.objectState,
      legendaState: getterTypes.legendaState,
      inspectionsState: getterTypes.inspectionsState,
      passportState: getterTypes.passportState,
      objectClicked: getterTypes.objectState,
      legendaClicked: getterTypes.legendaState,
    }),
  },
}
</script>
