<template>
  <canvas ref="can" width="200" height="200"></canvas>
</template>

<script>
import {fabric} from 'fabric'

export default {
  name: 'FabricCanvas',

  mounted() {
    const ref = this.$refs.can
    const canvas = new fabric.Canvas(ref)
    const rect = new fabric.Rect({
      fill: 'red',
      width: 200,
      height: 200,
    })
    canvas.add(rect)
  },
}
</script>
