import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth'
import nav from '@/store/modules/nav'
import map from '@/store/modules/map'
import player from '@/store/modules/player'
import inspections from '@/store/modules/inspections'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    nav,
    map,
    player,
    inspections,
  },
})
