const state = {
  percent: 0,
  image: '',
  url: '',
  isLoading: false,
}
export const mutationTypes = {
  percentChange: '[player] percentChange',
  imageChange: '[player] imageChange',
  urlChange: '[player] urlChange',
  isLoading: '[player] isLoading',
}

export const getterTypes = {
  percentState: '[player] percentState',
  image: '[player] image',
  url: '[player] url',
  isLoading: '[player] isLoading',
}
const getters = {
  [getterTypes.isLoading]: (state) => {
    return state.isLoading
  },
  [getterTypes.percentState]: (state) => {
    return state.percent
  },
  [getterTypes.image]: (state) => {
    return state.image
  },
  [getterTypes.url]: (state) => {
    return state.url
  },
}

const mutations = {
  [mutationTypes.isLoading](state, payload) {
    state.isLoading = payload
  },
  [mutationTypes.percentChange](state, payload) {
    state.percent = payload
  },
  [mutationTypes.imageChange](state, payload) {
    state.image = payload
  },
  [mutationTypes.urlChange](state, payload) {
    state.url = payload
  },
}

export default {
  state,
  //   actions,
  mutations,
  getters,
}
