import axios from '@/api/axios'
import {getItem} from '@/helpers/persistanceStorage'
let BASE_URL = process.env.VUE_APP_API_BASE_URL
const getCustomInspectionFields = () => {
  const bodyFormData = new FormData()
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  return axios.post(BASE_URL + `getCustomInspectionFields/`, bodyFormData)
}

const getGeneralInspections = () => {
  const bodyFormData = new FormData()
  const domain = getItem('curr-domain')
  let inspectionId = domain === 'gem-venray' ? '24' : '79'
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  bodyFormData.set('inspection_id', inspectionId)
  return axios.post(BASE_URL + 'getGeneralInspections/', bodyFormData)
}
const getCustomInspection = () => {
  const domain = getItem('curr-domain')
  const bodyFormData = new FormData()
  let inspectionId = domain === 'gem-venray' ? '23' : '80'
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  bodyFormData.set('inspection_id', inspectionId)
  return axios.post(BASE_URL + 'getCustomInspections/', bodyFormData)
}
const addInspection = (data) => {
  const bodyFormData = new FormData()
  if (data[4] === 'edit') {
    bodyFormData.set('insp_order', data[5])
  }
  if (data[2] === '79') {
    bodyFormData.set('objects[0]', data[0])
  } else if (data[2] === '80') {
    bodyFormData.set('object_id', data[0])
    bodyFormData.set('general_inspection_row', data[3])
    bodyFormData.set('general_inspection', '79')
  }
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  bodyFormData.set('inspection_id', data[2])
  const arr = data[1]

  for (var i = 0; i < arr.length; i++) {
    if (arr[i].type !== 'submit' && arr[i].type !== 'photo' && arr[i].type !== 'undefined') {
      bodyFormData.append(`rows[${i}][value]`, arr[i].value)
    } else if (arr[i].type === 'photo') {
      if (arr[i].value) {
        if (arr[i].value.url) {
          bodyFormData.append(`rows[${i}][value]`, arr[i].value.value)
        } else {
          bodyFormData.append(`rows[${i}]`, arr[i].value)
        }
      }
    }
    bodyFormData.append(`rows[${i}][type]`, arr[i].type)
    bodyFormData.append(`rows[${i}][row_id]`, arr[i].row_id)
    bodyFormData.append(`rows[${i}][id]`, arr[i].id)
  }
  return axios.post(BASE_URL + 'addCustomInspection/', bodyFormData)
}
const deleteInspection = (data) => {
  const bodyFormData = new FormData()
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  bodyFormData.set('object_id', data.object_id)

  bodyFormData.set('inspection_id', data.inspection_id)
  bodyFormData.set('insp_order', data.insp_order)

  return axios.post(BASE_URL + 'deleteCustomInspection/', bodyFormData)
}
const getInspectionFiles = (id) => {
  const bodyFormData = new FormData()
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  bodyFormData.set('object_id', id)
  bodyFormData.set('path', '/')
  bodyFormData.set('type', 'archive')
  return axios.post(BASE_URL + 'getFiles/', bodyFormData)
}
const editInspection = (data) => {
  const bodyFormData = new FormData()
  bodyFormData.set('version', '9.8')
  bodyFormData.set('access_token', getItem('accessToken'))
  bodyFormData.set('inspection_id', '5')
  bodyFormData.set('object_id', data[0])
  bodyFormData.set('insp_order', data[2])
  const arr = data[1]
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].type !== 'submit' && arr[i].type !== 'photo') {
      bodyFormData.append(`rows[${i}][type]`, arr[i].type)
      bodyFormData.append(`rows[${i}][value]`, arr[i].value)
      bodyFormData.append(`rows[${i}][row_id]`, arr[i].row_id)
      bodyFormData.append(`rows[${i}][id]`, arr[i].id)
    } else if (arr[i].type === 'photo') {
      if (arr[i].value) {
        if (arr[i].value.url) {
          bodyFormData.append(`rows[${i}][value]`, arr[i].value.value)
        } else {
          bodyFormData.append(`rows[${i}]`, arr[i].value)
        }
        bodyFormData.append(`rows[${i}][type]`, arr[i].type)
        bodyFormData.append(`rows[${i}][row_id]`, arr[i].row_id)
        bodyFormData.append(`rows[${i}][id]`, arr[i].id)
      }
    }
  }
  return axios.post(BASE_URL + 'addCustomInspection/', bodyFormData)
}
const getVideo = (url) => {
  return axios.get(url, {responseType: 'blob'})
}

export default {
  getCustomInspectionFields,
  addInspection,
  getCustomInspection,
  editInspection,
  deleteInspection,
  getInspectionFiles,
  getVideo,
  getGeneralInspections,
}
