<template>
  <div class="position-absolute object-menu">
    <app-object-menu />
  </div>
</template>
<script>
import AppObjectMenu from '@/components/nav/ObjectsMenu.vue'

export default {
  name: 'AppNav',
  components: {
    AppObjectMenu,
  },
}
</script>
<style scoped>
.object-menu {
  top: 30px;
  left: 30px;
}
</style>
