import axios from 'axios'

//axios.defaults.baseURL = 'https://api.iasset.nl/'
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Methods'] =
//   'OPTIONS, GET, POST, PUT, DELETE'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] =
//   'X-Requested-With, Content-Type, X-Token-Auth, Authorization'
//axios.interceptors.request.use((config) => {
//  config.headers['Access-Control-Allow-Methods'] = ''
// const token = getItem('accessToken')
// const authorizationToken = token ? `Token ${token}` : ''
// config.headers.Authorization = authorizationToken
// config.headers['Access-Control-Allow-Origin'] = '*'
// config.headers['access_token'] = ''
// config.headers['Api-Version'] = 1
//return config
//})
export default axios
