const state = {
  objectActive: false,
  legendaActive: false,
  passportActive: false,
  inspectionsActive: false,
  isLoading: false,
}
export const mutationTypes = {
  objectChange: '[nav] objectChange',
  objectSet: '[nav] objectSet',
  legendaChange: '[nav] legendaChange',
  passportChange: '[nav] passportChange',
  inspectionsChange: '[nav] inspectionsChange',
  isLoading: '[nav] isLoading',
}
// export const actionTypes = {
//   register: '[auth] register',
// }
export const getterTypes = {
  objectState: '[nav] objectState',
  legendaState: '[nav] legendaState',
  passportState: '[nav] passportState',
  inspectionsState: '[nav] inspectionstState',
  isLoading: '[nav] isLoading',
}

const getters = {
  [getterTypes.isLoading]: (state) => {
    return state.isLoading
  },
  [getterTypes.objectState]: (state) => {
    return state.objectActive
  },
  [getterTypes.legendaState]: (state) => {
    return state.legendaActive
  },
  [getterTypes.passportState]: (state) => {
    return state.passportActive
  },
  [getterTypes.inspectionsState]: (state) => {
    return state.inspectionsActive
  },
}

const mutations = {
  [mutationTypes.isLoading](state, payload) {
    state.isLoading = payload
  },
  [mutationTypes.objectChange](state, payload) {
    state.objectActive = payload
  },
  [mutationTypes.legendaChange](state, payload) {
    state.legendaActive = payload
    //    state.legendaActive = !state.legendaActive
  },
  [mutationTypes.inspectionsChange](state) {
    state.inspectionsActive = !state.inspectionsActive
  },
  [mutationTypes.passportChange](state) {
    state.passportActive = !state.passporttActive
  },
}
// const actions = {
//   [actionTypes.register](context, credentials) {
//     return new Promise((resolve) => {
//       context.commit(mutationTypes.registerStart)
//       authApi
//         .register(credentials)
//         .then((response) => {
//           context.commit(mutationTypes.registerSuccess, response.data.user)
//           setItem('accessToken', response.data.user.token)
//           resolve(response.data.user)
//         })
//         .catch((result) => {
//           context.commit(
//             mutationTypes.registerFailure,
//             result.response.data.errors
//           )
//         })
//     })
//   }
// }
export default {
  state,
  //   actions,
  mutations,
  getters,
}
