<template>
  <div ref="map" id="google_map" class="rounded-4 full"></div>
</template>
<script>
import _ from 'lodash'
import gsap from 'gsap'
import axios from 'axios'
import {getItem} from '@/helpers/persistanceStorage'
import {MarkerClusterer} from '@googlemaps/markerclusterer'
import {mapGetters} from 'vuex'
import {getterTypes as mapGetterTypes} from '@/store/modules/map'
import {getterTypes as navGetterTypes} from '@/store/modules/nav'
import {getterTypes as playerGetterTypes} from '@/store/modules/player'
import {getterTypes as inspectionsGetterTypes} from '@/store/modules/inspections'
import {mutationTypes as mapMutationTypes} from '@/store/modules/map'
import {mutationTypes as navMutationTypes} from '@/store/modules/nav'
import {actionTypes as mapActionTypes} from '@/store/modules/map'
import {actionTypes as inspectionsActionTypes} from '@/store/modules/inspections'
import {missingUrls} from '../helpers/missing.js'
export default {
  name: 'AppMap',
  data() {
    return {
      BASE_URL: process.env.VUE_APP_API_BASE_URL,
      fontData: require('@/assets/fonts/roboto.json'),
      map: null,
      reset: true,
      overlay: null,
      scene: null,
      percent: 0,
      mapCenter: {lat: 52.34367094502756, lng: 5.619902260762144},
      figuresArr: [],
      missingVidos: [],
      path: [],
      lineSymbol: null,
      icons: [],
      hasFigure: false,
      pointerPos: {},
      cameraOptions: null,
      tween: null,
      centerDiv: null,
      animationDiv: null,
      markerCluster: null,
      oldObjectsState: 0,
      customInspectionMarkers: [],
      oldGeneralInspection: null,
    }
  },

  computed: {
    ...mapGetters({
      //MAP
      isLoading: mapGetterTypes.isLoadingState,
      zoomState: mapGetterTypes.zoomState,
      filterRemoved: mapGetterTypes.filterRemoved,
      filterAdded: mapGetterTypes.filterAdded,
      //NAV
      passportState: navGetterTypes.passportState,
      objectState: navGetterTypes.objectState,
      legendaState: navGetterTypes.legendaState,
      //PLAYER
      percentState: playerGetterTypes.percentState,
      //INSPECTIONS
      objectClicked: inspectionsGetterTypes.objectClicked,
      newClicked: inspectionsGetterTypes.newClicked,
      selectedGeneralInspection: inspectionsGetterTypes.selectedGeneralInspection,
      customInspections: inspectionsGetterTypes.customInspection,
      generalInspections: inspectionsGetterTypes.generalInspections,
    }),
  },
  watch: {
    customInspections(val) {
      if (val.length) {
        this.customInspectionMarkers.map((marker) => {
          marker.setMap(null)
        })
        this.customInspectionMarkers.length = 0
        const objectInspections = this.customInspections.filter((inspection) => {
          return inspection.general_inspection_row === this.selectedGeneralInspection
        })
        const points = []
        objectInspections.map((inspection) => {
          let url
          if (inspection.data['1011']?.url) {
            url = inspection.data['1011'].url
          } else {
            url = URL.createObjectURL(inspection.data['1011'])
          }
          const re = /\((.*?)\s(.*?)\)/gm
          const pointsArr = re.exec(inspection.data['1012'])
          const newPoint = [{lat: Number(pointsArr[2]), lng: Number(pointsArr[1])}, url]
          points.push(newPoint)
        })
        this.drawMarkers(points)
      }
    },
    selectedGeneralInspection(val) {
      this.customInspectionMarkers.map((marker) => {
        if (marker.filterId === this.oldGeneralInspection) {
          marker.setMap(null)
        }
      })
      if (val) {
        this.oldGeneralInspection = val
        let objectInspectionsArr = []
        if (this.customInspections) {
          objectInspectionsArr = this.customInspections.filter((inspection) => {
            return inspection.general_inspection_row === val
          })
        }
        let points = []
        objectInspectionsArr.map((inspection) => {
          const re = /\((.*?)\s(.*?)\)/gm
          const pointsArr = re.exec(inspection.data['1012'])
          const url = inspection.data['1011'].url
          const newPoint = [{lat: Number(pointsArr[2]), lng: Number(pointsArr[1])}, url]
          points.push(newPoint)
        })

        this.drawMarkers(points)
      }
    },

    newClicked(val) {
      if (val) {
        this.$store.commit(mapMutationTypes.pointerPos, this.pointerPos)
      }
    },
    filterAdded(val) {
      if (val !== null) {
        this.getFilterObjects(val)
      }
    },
    filterRemoved: function (val) {
      if (val !== null) {
        this.removeObjects(val)
        this.$store.commit(mapMutationTypes.filterRemoved, null)
      }
    },
    objectClicked: function (obj) {
      if (obj !== null) {
        this.onObjectClicked(obj)
      }
    },
    percentState: function (val) {
      this.animateCircle(val)
    },
    zoomState: function (val) {
      this.map.setZoom(val)
    },
  },
  components: {},
  methods: {
    initMap() {
      this.centerDiv = document.createElement('div')
      this.centerDiv.style.background = 'white'
      this.centerDiv.style.padding = '5px'
      this.centerDiv.style.marginLeft = '10px'
      this.centerDiv.style.marginBottom = '5px'
      this.centerDiv.style.borderRadius = '5px'
      this.centerDiv.style.display = 'none'
      let controlUI = document.createElement('span')
      controlUI.classList.add('material-icons')
      controlUI.innerHTML = 'control_camera'
      controlUI.style.cursor = 'pointer'
      controlUI.style.fontSize = '30px'
      controlUI.style.color = '#666666'

      controlUI.title = 'Midden van de kaart'
      this.centerDiv.appendChild(controlUI)

      this.animationDiv = document.createElement('div')
      this.animationDiv.style.background = 'white'
      this.animationDiv.style.padding = '5px'
      this.animationDiv.style.marginLeft = '10px'
      this.animationDiv.style.marginBottom = '20px'
      this.animationDiv.style.borderRadius = '5px'
      let controlAnimationUI = document.createElement('span')
      controlAnimationUI.classList.add('material-icons')
      controlAnimationUI.innerHTML = 'animation'
      controlAnimationUI.style.cursor = 'pointer'
      controlAnimationUI.style.fontSize = '30px'
      controlAnimationUI.style.color = '#666666'
      controlAnimationUI.title = 'Deactiveer de camera animatie '
      this.animationDiv.appendChild(controlAnimationUI)

      this.cameraOptions = {
        tilt: 0,
        heading: 0,
        // mapTypeId: 'hybrid',
        zoom: 17,
        center: {lat: 52.34367094502756, lng: 5.619902260762144},
      }
      const mapOptions = {
        ...this.cameraOptions,
        mapTypeId: 'roadmap',
        mapTypeControl: true,
        minZoom: 5,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        scaleControl: false,
        rotateControl: true,
        fullscreenControl: false,
        mapId: 'c6697ab5979694b9',
      }
      this.map = new window.google.maps.Map(document.getElementById('google_map'), mapOptions)
      this.lineSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 8,
        strokeColor: '#2C97D6',
        fillColor: '#2C97D6',
        fillOpacity: 1,
        strokeWeight: 1,
      }
      window.google.maps.LatLng.prototype.distanceFrom = function (newLatLng) {
        var EarthRadiusMeters = 6378137.0 // meters
        var lat1 = this.lat()
        var lon1 = this.lng()
        var lat2 = newLatLng.lat()
        var lon2 = newLatLng.lng()
        var dLat = ((lat2 - lat1) * Math.PI) / 180
        var dLon = ((lon2 - lon1) * Math.PI) / 180
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        var d = EarthRadiusMeters * c
        return d
      }
      window.google.maps.Polyline.prototype.GetPointAtDistance = function (metres) {
        // some awkward special cases
        if (metres == 0) return this.getPath().getAt(0)
        if (metres < 0) return null
        if (this.getPath().getLength() < 2) return null
        var dist = 0
        var olddist = 0
        for (var i = 1; i < this.getPath().getLength() && dist < metres; i++) {
          olddist = dist
          dist += this.getPath()
            .getAt(i)
            .distanceFrom(this.getPath().getAt(i - 1))
        }
        if (dist < metres) {
          return null
        }
        var p1 = this.getPath().getAt(i - 2)
        var p2 = this.getPath().getAt(i - 1)
        var m = (metres - olddist) / (dist - olddist)
        return new window.google.maps.LatLng(p1.lat() + (p2.lat() - p1.lat()) * m, p1.lng() + (p2.lng() - p1.lng()) * m)
      }
      const map = this.map
      const markers = this.figuresArr
      this.markerCluster = new MarkerClusterer({map, markers})
      controlUI.addEventListener('click', () => {
        this.resetTilt()
      })
      controlAnimationUI.addEventListener('click', () => {
        this.resetMap()
      })
      this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(this.animationDiv)
      this.map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(this.centerDiv)
      this.map.data.addListener('click', (event) => {
        const feature = event.feature
        if (feature) {
          this.$store.commit(mapMutationTypes.objectClicked, feature)
        }
      })
      // window.google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
      //   gsap.to(this.cameraOptions, {
      //     // tilt: 65,
      //     // heading: 90,
      //     zoom: 19,
      //     duration: 1,
      //     ease: 'power4.easeInOut',
      //     onUpdate: () => {
      //       this.map.moveCamera({
      //         tilt: this.cameraOptions.tilt,
      //         heading: this.cameraOptions.heading,
      //         zoom: this.cameraOptions.zoom,
      //         center: this.cameraOptions.center,
      //       })
      //     },
      //   })
      // })
    },
    async getFilterObjects(id) {
      this.$store.commit(navMutationTypes.isLoading, true)
      const bodyFormData = new FormData()
      bodyFormData.set('version', '9.8')
      bodyFormData.set('access_token', getItem('accessToken'))
      bodyFormData.set('page', 1)
      bodyFormData.set('selected_filter', id)
      const res = await axios.post(this.BASE_URL + 'getObjectsNew/', bodyFormData)
      this.drawGeometries(res.data.returndata.objects, id)
      if (res.data.returndata.objectsInFilter) {
        const pages = Math.ceil(res.data.returndata.objectsInFilter / 1000)
        if (pages > 1) {
          for (let i = 2; i <= pages; i++) {
            this.$store.commit(navMutationTypes.isLoading, true)
            bodyFormData.set('page', i)
            const r = await axios.post(this.BASE_URL + 'getObjectsNew/', bodyFormData)
            this.drawGeometries(r.data.returndata.objects, id)
            if (i === pages) {
              this.$store.commit(navMutationTypes.isLoading, false)
            }
          }
        } else {
          this.$store.commit(navMutationTypes.isLoading, false)
        }
      } else {
        this.$store.commit(navMutationTypes.isLoading, false)
      }
    },
    async drawGeometries(objs, filterId) {
      if (objs) {
        const domain = getItem('curr-domain')
        const features = []
        const tableObjs = []
        for (let obj of objs) {
          let url = ''
          let hasVideo = true
          if (domain === 'gem-venray') {
            const objectInspection = this.generalInspections.find((inspection) => {
              return inspection.object_id === obj.id
            })
            if (objectInspection) {
              const folder = objectInspection.data[766]
              const file = objectInspection.data[767].slice(0, -3)
              url = `/riolering/Rijdende Inspectie/Suf 2.0 + 2.1/${folder}/${file}mp4`
            }
            hasVideo = !missingUrls.includes(url)
          }
          obj.filter = filterId
          if (obj.points && hasVideo) {
            // this.objectIds.add(obj.id);
            let selected = false
            // if (this.objectClickedState) {
            //     if (this.objectClickedState.recid === obj.id) {
            //         selected = true;
            //     }
            // }
            tableObjs.push({
              recid: obj.id,
              number: obj.number,
              name: obj.name,
              filters: filterId,
            })
            let feature
            let coordinates
            const typeRegx = /^[^(]*/
            const type = typeRegx.exec(obj.points)
            switch (type[0]) {
              case 'POLYGON': {
                const pointsRegx = /(?<=\(\().*?(?=\)\))/
                const pointsStr = pointsRegx.exec(obj.points)
                const pointsArr = pointsStr[0].split(',')
                coordinates = pointsArr.map((point) => {
                  const pointArr = point.split(' ').map((coord) => Number(coord))
                  return pointArr
                })
                const polyBounds = new window.google.maps.LatLngBounds()
                _.each(coordinates, (point) => {
                  polyBounds.extend(new window.google.maps.LatLng(point[1], point[0]))
                })
                feature = {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: [coordinates],
                  },
                  properties: {
                    fields: obj.fields,
                    subthema: obj.subthema,
                    thema: obj.thema,
                    filters: filterId,
                    bounds: polyBounds,
                    selected,
                    points: [obj.lat, obj.lon],
                    name: obj.name,
                    number: obj.number,
                  },
                  id: obj.id,
                }
                break
              }
              case 'MULTIPOLYGON': {
                const polyRegx = /(?<=\().*?(?=\))/g
                const polyStrArr = obj.points.match(polyRegx)
                polyStrArr[0] = polyStrArr[0].replaceAll('(', '')
                coordinates = polyStrArr.map((polygon) => {
                  const pointsStrArr = polygon.split(',')
                  const pointsArr = pointsStrArr.map((point) => {
                    const coordinates = point.split(' ').map((coord) => Number(coord))
                    return coordinates
                  })
                  return pointsArr
                })
                const multiPolyBounds = new window.google.maps.LatLngBounds()
                _.each(coordinates[0], (point) => {
                  multiPolyBounds.extend(new window.google.maps.LatLng(point[1], point[0]))
                })
                feature = {
                  type: 'Feature',
                  geometry: {
                    type: 'MultiPolygon',
                    coordinates: [coordinates],
                  },
                  properties: {
                    fields: this.filterAdded,
                    subthema: obj.subthema,
                    thema: obj.thema,
                    filters: filterId,
                    bounds: multiPolyBounds,
                    points: [obj.lat, obj.lon],
                    name: obj.name,
                    number: obj.number,
                    selected,
                  },
                  id: obj.id,
                }
                break
              }
              case 'LINESTRING': {
                const myReL = /\((.*)\)/gm
                const pointsStrL = myReL.exec(obj.points)
                const pointsArrStr = pointsStrL[1].split(',')
                coordinates = pointsArrStr.map((point) => {
                  const pointArr = point.split(' ').map((coord) => Number(coord))
                  return pointArr
                })
                const lineBounds = new window.google.maps.LatLngBounds()
                _.each(coordinates, (point) => {
                  lineBounds.extend(new window.google.maps.LatLng(point[1], point[0]))
                })
                feature = {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: coordinates,
                  },
                  properties: {
                    fields: obj.fields,
                    bounds: lineBounds,
                    subthema: obj.subthema,
                    thema: obj.thema,
                    filters: filterId,
                    points: [obj.lat, obj.lon],
                    name: obj.name,
                    number: obj.number,
                    selected,
                  },
                  id: obj.id,
                }
                break
              }
              case 'POINT': {
                const myRe = /\((.*)\)/gm
                const myArray = myRe.exec(obj.points)
                const pointsCouple = myArray[1].split(' ')
                if (!isNaN(Number(pointsCouple[0])) && !isNaN(Number(pointsCouple[1]))) {
                  coordinates = [Number(pointsCouple[0]), Number(pointsCouple[1])]
                  feature = {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: coordinates,
                    },
                    properties: {
                      fields: obj.fields,
                      subthema: obj.subthema,
                      thema: obj.thema,
                      filters: filterId,
                      points: [obj.lat, obj.lon],
                      name: obj.name,
                      number: obj.number,
                      bounds: {lng: coordinates[0], lat: coordinates[1]},
                      selected,
                    },
                    id: obj.id,
                  }
                }
                break
              }
              default: {
                break
              }
            }
            features.push(feature)
          }
        }
        this.$emit('features', tableObjs)
        this.map.data.addGeoJson({type: 'FeatureCollection', features})
        this.$store.commit(mapMutationTypes.filterAdded, null)
      }
    },
    drawMarkers(points) {
      points.forEach((point) => {
        const marker = new window.google.maps.Marker({
          position: point[0],
          map: this.map,
          filterId: this.selectedGeneralInspection,
        })
        this.customInspectionMarkers.push(marker)
        const contentString = `<img class="rounded-4" style="cursor:pointer" src="${point[1]}" alt="Screenshot" width="240" height="160">`
        const infowindow = new window.google.maps.InfoWindow({
          content: contentString,
          disableAutoPan: true,
        })
        // var textMesh = null
        window.google.maps.event.addListener(marker, 'mouseover', (event) => {
          if (event.latLng) {
            infowindow.setPosition(event.latLng)
            infowindow.open({
              map: this.map,
              shouldFocus: false,
            })
          }
        })
        window.google.maps.event.addListener(marker, 'mouseout', (event) => {
          setTimeout(() => {
            infowindow.close()
          }, 200)
        })
      })
    },
    resetTilt() {
      gsap.to(this.cameraOptions, {
        heading: 0,
        duration: 1,
        ease: 'power4.easeInOut',
        onUpdate: () => {
          this.map.moveCamera({
            heading: this.cameraOptions.heading,
          })
        },
      })
    },
    resetMap() {
      this.reset = !this.reset
      if (this.reset) {
        this.centerDiv.style.display = 'none'
        this.map.setMapTypeId('terrain')
        gsap.to(this.cameraOptions, {
          tilt: 0,
          heading: 0,
          zoom: 17,
          duration: 2,
          ease: 'power4.easeInOut',
          onUpdate: () => {
            this.map.moveCamera({
              tilt: this.cameraOptions.tilt,
              heading: this.cameraOptions.heading,
              zoom: this.cameraOptions.zoom,
            })
          },
        })
      } else {
        this.centerDiv.style.display = 'inline'
        this.map.setMapTypeId('roadmap')
        gsap.to(this.cameraOptions, {
          tilt: 65,
          heading: 90,
          zoom: 19,
          duration: 4,
          ease: 'power4.easeInOut',
          onUpdate: () => {
            this.map.moveCamera({
              tilt: this.cameraOptions.tilt,
              heading: this.cameraOptions.heading,
              zoom: this.cameraOptions.zoom,
            })
          },
        })
      }
    },
    animateCircle(val) {
      this.icons = this.path.get('icons')
      this.icons[0].offset = val + '%'
      this.path.set('icons', this.icons)
      const lineLength = window.google.maps.geometry.spherical.computeLength(this.path.getPath())
      const meters = (lineLength / 100) * val
      const position = this.path.GetPointAtDistance(meters)
      this.map.panTo(position)
      this.pointerPos = {
        lat: position.lat(),
        lng: position.lng(),
      }
    },
    onObjectClicked(obj) {
      const feature = this.map.data.getFeatureById(obj.recid)
      const points = {
        lat: feature.getProperty('points')[0],
        lng: feature.getProperty('points')[1],
      }
      const featurePoints = feature.getGeometry().getArray()
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(this.map.getCenter(), points)
      this.inspectionActive = true
      if (this.reset || distance > 2000) {
        this.map.setCenter({
          ...points,
          zoom: 19,
        })
      } else {
        gsap.to(this.cameraOptions.center, {
          // heading: this.cameraOptions.heading + 20,
          ...points,
          duration: 2,
          ease: 'power4.easeOut',
          onUpdate: () => {
            this.cameraOptions.heading = this.cameraOptions.heading + 0.5
            this.map.moveCamera({
              heading: this.cameraOptions.heading,
              center: {
                lat: this.cameraOptions.center.lat,
                lng: this.cameraOptions.center.lng,
              },
            })
          },
        })
      }

      if (feature.getProperty('fields').objects_pin_type === 'polyline') {
        if (this.hasFigure) {
          this.path.setMap(null)
        }
        this.pointerPos = {
          lat: featurePoints[0].lat(),
          lng: featurePoints[0].lng(),
        }
        this.path = new window.google.maps.Polyline({
          path: featurePoints,
          strokeColor: '#000000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          filterId: feature.getProperty('filters'),
          icons: [
            {
              icon: this.lineSymbol,
              offset: '0%',
            },
          ],
          map: this.map,
        })
        this.hasFigure = true
        this.showVideo = true
      }

      // this.$store.commit(mapMutationTypes.zoomChange, 20)

      this.map.panTo(points)
    },
    removeObjects(filterId) {
      if (this.path !== null && this.path.filterId === filterId) {
        this.path.setMap(null)
        this.hasFigure = false
      }
      this.map.data.forEach((feature) => {
        const filters = feature.getProperty('filters')
        if (filters === filterId) {
          this.map.data.remove(feature)
        }
      })
      this.$store.commit(mapMutationTypes.isLoading, false)
    },
    checkAndAttachMapScript(callback) {
      let scriptId = 'map-api-script'
      let mapAlreadyAttached = !!document.getElementById(scriptId)

      if (mapAlreadyAttached) {
        if (window.google) {
          callback()
        }
      } else {
        window.mapApiInitialized = callback
        let script = document.createElement('script')
        script.id = scriptId
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API}&libraries=geometry,drawing&callback=mapApiInitialized&v=beta`
        document.body.appendChild(script)
      }
      return mapAlreadyAttached
    },
  },
  async mounted() {
    this.checkAndAttachMapScript(this.initMap)
    this.$store.dispatch(mapActionTypes.filters)
    this.$store.dispatch(inspectionsActionTypes.getInspections)
    this.$store.dispatch(inspectionsActionTypes.getCustomInspection)
    this.$store.dispatch(inspectionsActionTypes.getGeneralInspections)
  },
}
</script>
<style>
.col-empty,
.col:empty,
[class^='col-']:empty {
  display: none;
}
.tooltip {
  border: thin 1px #eee;
  background-color: #fffbf0;
  padding: 5px;
}
.full {
  width: 100%;
  height: 100%;
}
.half {
  height: 43%;
}
.vue-grid-item {
  height: 100%;
  width: 100%;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}
/* /////////////////////////////////////////// */

.slide-fade-left-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-left-leave-active {
  transition: all 0.3s ease-in;
}
.slide-fade-left-enter, .slide-fade-left-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}
/* /////////////////////////////////// */
.slide-fade-right-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-right-leave-active {
  transition: all 0.3s ease-in;
}
.slide-fade-right-enter, .slide-fade-right-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}

/* //////////////////////////// */
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.3s ease-in;
}
.fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.gm-style-iw {
  background-color: rgb(236, 241, 250, 0.8) !important;
  overflow: hidden !important;
  padding: 10px !important;
  font-size: 20px !important;
}
.gm-style .gm-style-iw-t::after {
  background-color: rgb(236, 241, 250, 0.8) !important;
  background: rgb(236, 241, 250, 0.8) !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
</style>
